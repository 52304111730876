'use client'

import { useMemo } from 'react'
import { Card } from '~/scalis-components/core'
import { Body, BodyColor, BodySize } from '~/scalis-components/core/typography'
import { Login } from '~/scalis-components/team/authentication/login'
import { PoweredByFooter } from '~/scalis-components/team/authentication/powered-by-footer'
import { Routes } from '~/src/app/_constants/routes'
import { useEmployerBranded } from '~/src/app/jobs/hooks/use-employer-branded'

export const LoginPage = () => {
  const { hasCompanyData, hasJobListingToApply, companyName, jobListingTitle, callbackUrl, companyLogo } =
    useEmployerBranded()

  const isCallbackUrlToApplicationFlow = useMemo(() => {
    const urlsToCheck: string[] = [Routes.application_flow.profile]

    if (typeof window !== 'undefined') {
      urlsToCheck.push(`${window.location.origin}${Routes.application_flow.profile}`)
    }

    return urlsToCheck.some((url) => callbackUrl?.startsWith(url))
  }, [callbackUrl])

  return (
    <div className='mx-4 flex w-full flex-col items-center gap-6'>
      {hasCompanyData && hasJobListingToApply && isCallbackUrlToApplicationFlow && (
        <p className='mb-2 text-sm text-neutral-secondary'>Application for {jobListingTitle}</p>
      )}
      <Card className='w-full max-w-[448px] rounded-3xl bg-neutral-00 py-8 px-6'>
        <Login
          companyName={hasCompanyData ? companyName : undefined}
          companyLogo={hasCompanyData ? companyLogo : undefined}
        />
      </Card>
      {hasCompanyData && (
        <div className='flex flex-col items-center gap-2'>
          <Body
            size={BodySize.small}
            color={BodyColor.secondary}
          >
            ©{new Date().getFullYear()} SCALIS, LLC. All Rights Reserved.
          </Body>

          <PoweredByFooter />
        </div>
      )}
    </div>
  )
}
