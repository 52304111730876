import { signIn } from 'next-auth/react'
import { getCookie, setCookie } from 'cookies-next'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { notification } from '~/scalis-components/core/notification'
import { LoginFormValues } from '~/scalis-components/team/authentication/login/components/login-form'
import { AuthMethod } from '~/scalis-components/team/authentication/login/login.types'
import { Routes } from '~/src/app/_constants/routes'
import { JOB_LISTING_ID_TO_APPLY } from '~/src/app/application/application.constants'
import { Company, CompanyWorkspace } from '~/types/auth/TokenPayload'
import { getCallbackUrl } from '~/utils/get-callback-url'
import logger from '~/utils/logger'
import { getUser } from '~/v2/helpers/workspace-helper'
import { notificationErrorHandler } from '../error'
import { getClientSubdomain } from './get-client-subdomain'

export type HandleSignInOptions = {
  method: AuthMethod
  data?: LoginFormValues
  setAuthError: (error: string | null) => void
  refetchUserProvider: () => Promise<{
    user: Awaited<ReturnType<typeof getUser>> | null
    currentWorkspace: CompanyWorkspace | null
    companies: Company[] | null
  }>
  router: AppRouterInstance
  onLoginSuccess?: () => void
}

export const handleSignIn = async (options: HandleSignInOptions) => {
  const { method, data, setAuthError, refetchUserProvider, router, onLoginSuccess } = options
  setAuthError(null)

  // there is a bug that nextauth does not remove old cookies when signing out so we need to manually remove them
  await fetch('/api/cookie-reset', { method: 'POST' })

  let response
  if (method === AuthMethod.credentials && data) {
    response = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    })
  } else if (method === AuthMethod.google) {
    const callbackUrl = getCallbackUrl()
    const subdomain = getClientSubdomain()

    if (callbackUrl)
      setCookie('callbackUrl', callbackUrl, {
        path: '/',
        domain: window.location.hostname.split('.').slice(-2).join('.'),
      })
    if (subdomain)
      setCookie('subdomain', subdomain, { path: '/', domain: window.location.hostname.split('.').slice(-2).join('.') })

    response = await signIn('google-cognito', {
      redirect: false,
      callbackUrl: `${process.env.NEXT_PUBLIC_APP_URL}/social-auth-completed`,
    })
  }

  if (response?.error) {
    setAuthError(response.error)
  } else {
    refetchUserProvider().then(({ user, companies }) => {
      if (onLoginSuccess) onLoginSuccess()
      else if (user && companies) pushUserAfterLogin({ user, companies, router })
    })
  }
}

export type PushUserAfterLoginOptions = {
  user: Awaited<ReturnType<typeof getUser>>
  companies: Company[]
  router: AppRouterInstance
  socialAuth?: boolean
}

export const pushUserAfterLogin = (options: PushUserAfterLoginOptions) => {
  const { user, companies, router, socialAuth } = options
  const primaryEmail = user?.EmailVerification?.find((email) => email.isPrimary) ?? null
  if (primaryEmail?.emailVerified) {
    pushUserToWorkspace({ user, companies, router, socialAuth })
  } else {
    const jobListingId = getCookie(JOB_LISTING_ID_TO_APPLY)
    router.replace(
      `/email-validation?userId=${user?.id}&email=${encodeURIComponent(primaryEmail?.emailAddress ?? '')}${
        jobListingId ? `&jobListingId=${jobListingId}` : ''
      }`,
    )
  }
}

export type PushUserToWorkspaceOptions = {
  user: Awaited<ReturnType<typeof getUser>>
  companies: Company[]
  router: AppRouterInstance
  socialAuth?: boolean
}

const pushUserToWorkspace = (options: PushUserToWorkspaceOptions) => {
  const { user, companies, router, socialAuth } = options
  try {
    const hasAvailableWorkspaces = companies.length
    const hasOnlyOneWorkspace = companies.length === 1
    const callbackUrl = getCallbackUrl()

    const accountSelectorUrl = new URL(Routes.auth.account_selector, window.location.origin)
    const userProfileUrl = new URL(Routes.user.profile, window.location.origin)

    const appendQueryParam = (url: URL, key: string, value: string | undefined | null) => {
      if (value) url.searchParams.append(key, value)
    }

    if (callbackUrl) {
      // it's important to use window.location.href because router.push does not trigger a rehydration of the page
      window.location.href = callbackUrl
      return
    }

    if (hasAvailableWorkspaces && hasOnlyOneWorkspace) {
      appendQueryParam(accountSelectorUrl, 'workspaceId', companies[0].id.toString())
      return router.push(accountSelectorUrl.toString())
    }

    if (hasAvailableWorkspaces && !hasOnlyOneWorkspace) {
      appendQueryParam(accountSelectorUrl, 'showWorkspacesList', 'true')
      return router.push(accountSelectorUrl.toString())
    }

    if (!hasAvailableWorkspaces) {
      const hasCompleteNameAndResume = user?.firstName || user?.lastName
      if (!hasCompleteNameAndResume) {
        let url = Routes.application_flow.name_resume
        if (socialAuth) url += '?socialAuth=true'
        return router.push(url)
      }

      return router.push(userProfileUrl.toString())
    }

    return router.push(userProfileUrl.toString())
  } catch (error) {
    logger.error(error)
    notificationErrorHandler({
      notification,
      responseError: error,
    })
  }
}
