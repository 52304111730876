import Image from 'next/image'
import Link from 'next/link'
import scalisLogo from '~/public/images/SCALIS_LOGO_BLUE.svg'
import { Body, BodyColor, BodySize } from '~/scalis-components/core/typography'

export const PoweredByFooter = () => {
  return (
    <div className='flex items-center gap-2'>
      <Body
        size={BodySize.small}
        color={BodyColor.secondary}
      >
        Powered by
      </Body>

      <Link
        href='/'
        target='_blank'
      >
        <div className='relative h-[16px] w-[76px]'>
          <Image
            src={scalisLogo}
            layout='fill'
            alt='scalis-logo'
            priority
          />
        </div>
      </Link>
    </div>
  )
}
