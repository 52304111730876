export const PASSWORD_VALIDATION_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/
export const PASSWORD_VALIDATION_MESSAGE =
  'Use at least 8 characters, with uppercase, lowercase, number and special character.'

export const FIELD_NAME_PASSWORD = 'password'
export const FIELD_NAME_EMAIL = 'email'

export const DEFAULT_VALUES_LOGIN = {
  [FIELD_NAME_EMAIL]: '',
  [FIELD_NAME_PASSWORD]: '',
}

export const getDefaultValues = () => {
  return DEFAULT_VALUES_LOGIN
}

export const FORM_FIELDS = {
  [FIELD_NAME_EMAIL]: {
    label: 'Email',
    placeholder: 'Enter your email',
    invalidErrorMessage: 'Invalid email',
    requiredErrorMessage: 'Email is required',
  },
  [FIELD_NAME_PASSWORD]: {
    label: 'Password',
    placeholder: 'Enter your password',
    requiredErrorMessage: 'Password is required',
    invalidErrorMessage: PASSWORD_VALIDATION_MESSAGE,
  },
}
