'use client'

import { useState } from 'react'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/navigation'
import { Button, ButtonWidth } from '@scalis/components/core/button'
import { Body as BodyText, BodySize as TextSize, Title } from '@scalis/components/core/typography'
import useSourceJob from '~/hooks/jobseeker/useSourceJob'
import { toast } from '~/hooks/useToast'
import { CompanyAvatar } from '~/scalis-components/core/company-avatar'
import { useUserContext } from '~/src/providers/user-provider'
import { handleSignIn } from '~/utils/auth/handle-sign-in'
import { errorHandler } from '~/utils/error'
import logger from '~/utils/logger'
import { LOGIN_MAP, LoginProps, AuthMethod } from '.'
import { LoginForm, LoginFormValues } from './components/login-form'

export const Login = ({ companyName, hideText, companyLogo, onLoginSuccess }: LoginProps) => {
  const [authError, setAuthError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { storeSourceJob } = useSourceJob()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get('callbackUrl')
  const { refetchUserProvider } = useUserContext()
  const router = useRouter()

  const handleSubmit = async (data: LoginFormValues) => {
    setIsSubmitting(true)
    try {
      storeSourceJob()
      await handleSignIn({
        method: AuthMethod.credentials,
        data,
        setAuthError,
        refetchUserProvider,
        router,
        onLoginSuccess,
      })
    } catch (error) {
      errorHandler(toast, error)
      logger.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      storeSourceJob()
      await handleSignIn({ method: AuthMethod.google, setAuthError, refetchUserProvider, router })
    } catch (error) {
      errorHandler(toast, error)
      logger.error(error)
    }
  }

  const onErrorSubmit = (error: any) => {
    logger.error(error)
    setIsSubmitting(false)
  }

  return (
    <div className='flex flex-col gap-6'>
      {!!companyLogo && (
        <div className='flex items-center justify-center'>
          <CompanyAvatar
            size='3xl'
            name={companyName ?? ''}
            imageSrc={companyLogo}
          />
        </div>
      )}

      {!hideText && (
        <div className='flex w-full flex-col'>
          <Title center>{companyName ? LOGIN_MAP.brandedTitle : LOGIN_MAP.title}</Title>
          <BodyText
            center
            size={TextSize.small}
          >
            {LOGIN_MAP.subtitle(companyName)}
          </BodyText>
        </div>
      )}
      <LoginForm
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onErrorSubmit={onErrorSubmit}
        authError={authError}
      />
      <div className='flex flex-col gap-6'>
        <Button
          width={ButtonWidth.full}
          aria-label={LOGIN_MAP.labelSubmit}
          loading={isSubmitting}
          onClick={() => setIsSubmitting(true)}
        >
          {LOGIN_MAP.labelSubmit}
        </Button>

        <div className='relative flex items-center justify-center'>
          <hr className='w-full border-gray-200' />
          <span className='absolute bg-white px-2 text-sm text-gray-500'>or</span>
        </div>
        <Button
          type='button'
          width={ButtonWidth.full}
          onClick={handleGoogleSignIn}
          loading={isSubmitting}
          className='flex items-center justify-center gap-2 bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 hover:text-gray-900 focus:ring-2 focus:ring-gray-200 focus:border-gray-400 active:bg-gray-100'
        >
          <div className='flex items-center justify-center gap-2'>
            <svg
              width='18'
              height='18'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 48 48'
            >
              <path
                fill='#EA4335'
                d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'
              />
              <path
                fill='#4285F4'
                d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'
              />
              <path
                fill='#FBBC05'
                d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'
              />
              <path
                fill='#34A853'
                d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'
              />
              <path
                fill='none'
                d='M0 0h48v48H0z'
              />
            </svg>
            <span>Continue with Google</span>
          </div>
        </Button>
      </div>
      {!hideText && (
        <div className='flex flex-col gap-4'>
          <BodyText
            center
            size={TextSize.small}
          >
            {LOGIN_MAP.helperText}{' '}
            <Link
              href={LOGIN_MAP.getHelperLink(callbackUrl)}
              className='font-medium text-brand-primary-rest'
            >
              {LOGIN_MAP.helperLinkText}
            </Link>
          </BodyText>
        </div>
      )}
    </div>
  )
}
