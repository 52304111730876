import { z } from 'zod'
import { LoginFormValues } from './components/login-form'

export const loginSchema = z.object({
  email: z.string(),
  password: z.string(),
})

export enum AuthMethod {
  credentials = 'credentials',
  google = 'google',
}

export type LoginBody = (credentials: z.input<typeof loginSchema>) => Promise<unknown>

export interface LoginProps {
  onSubmit?: (method: AuthMethod, data?: LoginFormValues) => Promise<void>
  onContinueWithGoogle?: () => void
  authError?: string | null
  companyName?: string | null
  companyLogo?: string | null
  hideText?: boolean
  onLoginSuccess?: () => void
}
