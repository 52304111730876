export const LOGIN_MAP = {
  title: 'Welcome back!',
  subtitle: (companyName?: string | null) =>
    companyName
      ? `${companyName} uses SCALIS profiles for applications. Please sign into your SCALIS profile to apply.`
      : 'Enter your information below to access SCALIS.',
  brandedTitle: 'Sign In',
  labelSubmit: 'Login',
  helperText: "Don't have an account?",
  forgotPasswordText: 'Forgot your password?',
  helperLinkText: 'Sign up!',
  getHelperLink: (callbackUrl?: string | null) =>
    callbackUrl ? `/signup?callbackUrl=${encodeURIComponent(callbackUrl)}` : '/signup',
  forgotPasswordLink: '/forgot-password',
  loginLink: '/login',
}
