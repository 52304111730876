// Convenience function for getting the callbackUrl from the search params
// It also prepends the current domain and protocol if the callbackUrl is just a path
// which provides some consistency in the expected callbackUrl format
export const getCallbackUrl = () => {
  const searchParams = new URLSearchParams(window.location.search)
  let callbackUrl = searchParams.get('callbackUrl') ? decodeURIComponent(searchParams.get('callbackUrl') || '') : null

  if (callbackUrl && !callbackUrl.startsWith('http')) {
    // If the callbackUrl is just a path, prepend the current domain and protocol
    const { protocol, host } = window.location
    callbackUrl = `${protocol}//${host}${callbackUrl}`
  }

  return callbackUrl
}
