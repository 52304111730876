import { z } from 'zod'

import {
  FIELD_NAME_EMAIL,
  FIELD_NAME_PASSWORD,
  FORM_FIELDS,
  PASSWORD_VALIDATION_MESSAGE,
  PASSWORD_VALIDATION_REGEX,
} from './login-form.constants'

export const createSchema = () => {
  return z.object({
    [FIELD_NAME_EMAIL]: z
      .string()
      .min(1, { message: FORM_FIELDS[FIELD_NAME_EMAIL].requiredErrorMessage })
      .email(FORM_FIELDS[FIELD_NAME_EMAIL].invalidErrorMessage),
    [FIELD_NAME_PASSWORD]: z
      .string()
      .min(1, {
        message: FORM_FIELDS[FIELD_NAME_PASSWORD].requiredErrorMessage,
      })
      .regex(PASSWORD_VALIDATION_REGEX, PASSWORD_VALIDATION_MESSAGE),
  })
}

export interface LoginProps {
  onSubmit?: (data: LoginFormValues) => void
  onErrorSubmit?: (error: any) => void
  onContinueWithGoogle?: () => void
  isSubmitting?: boolean
  defaultValues?: Partial<LoginFormValues>
  authError?: string | null
}

export interface LoginFormValues {
  email: string
  password: string
}
