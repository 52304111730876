'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'

import { Input, PasswordInput } from '@scalis/components/core/input'
import { FIELD_NAME_EMAIL, FIELD_NAME_PASSWORD, FORM_FIELDS, getDefaultValues } from './login-form.constants'
import { LoginFormValues, LoginProps, createSchema } from './login-form.types'
import Link from 'next/link'
import { LOGIN_MAP } from '../../login.constants'

export const LoginForm: React.FC<LoginProps> = ({
  onSubmit,
  onErrorSubmit,
  isSubmitting,
  defaultValues,
  authError,
}) => {
  const schema = createSchema()
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues ?? getDefaultValues(),
  })

  const formRef = React.useRef<HTMLFormElement | null>(null)

  React.useEffect(() => {
    if (isSubmitting) {
      formRef?.current?.requestSubmit()
    }
  }, [isSubmitting])

  const handleChange = (name: string, value: any) => {
    setValue(name as keyof LoginFormValues, value)
  }

  return (
    <form
      ref={formRef}
      className='flex flex-col gap-4'
      onSubmit={handleSubmit(onSubmit!, onErrorSubmit)}
    >
      <Input
        label={FORM_FIELDS[FIELD_NAME_EMAIL].label}
        name={FIELD_NAME_EMAIL}
        placeholder={FORM_FIELDS[FIELD_NAME_EMAIL].placeholder}
        error={errors?.[FIELD_NAME_EMAIL]?.message || (authError ? ' ' : undefined)} // Validation errors take precedence
        setValue={handleChange}
      />
      <PasswordInput
        label={FORM_FIELDS[FIELD_NAME_PASSWORD].label}
        name={FIELD_NAME_PASSWORD}
        placeholder={FORM_FIELDS[FIELD_NAME_PASSWORD].placeholder}
        error={errors?.[FIELD_NAME_PASSWORD]?.message || (authError ? ' ' : undefined)} // Validation errors take precedence
        setValue={handleChange}
      />
      {authError && !Object.keys(errors).length && <div className='-mt-2 text-xs text-red-500'>{authError}</div>}
      <div className='flex justify-end'>
        <Link
          href={LOGIN_MAP.forgotPasswordLink}
          className='text-xs font-medium text-brand-primary-rest'
        >
          {LOGIN_MAP.forgotPasswordText}
        </Link>
      </div>
    </form>
  )
}
